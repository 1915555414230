import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Modal from "react-modal";
import { add } from "date-fns";
import banner from "../../assets/banner_loja.png";
import bannerBlk from "../../assets/Banner_blk.jpg";

import { databoa } from "../../services/utils";
import api from "../../services/api";
import { handleGetCache, clearCart, moeda, nl2br } from "../../services/utils";
import {
  ALBUM_LIMIT,
  EXIBE_UP_COMPLEMENTAR,
  FLAG_CONVIDADO_ID,
} from "./../../configs/consts";
import { StateContext } from "./../../context/Context";

/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */

import ContractItem from "./ContractItem";
import DashboardItem from "./DashboardItem";
import OrderItem from "./OrderItem";
import OrderDetails from "./OrderDetails";
import Uploads from "../Uploads";
import RelatorioPagamentos from "./RelatorioPagamentos";
import RelatorioArquivos from "./RelatorioArquivos";
import Camisetas from "./Camisetas";
import med_camisetas_g from "../../assets/medidas_camiseta_g.png";
import help from "../../assets/icons/svg/flat/046-customer-service.svg";

import {
  faComments,
  faPhotoVideo,
  faFileContract,
  faShoppingBag,
  faCloudUploadAlt,
  faTools,
  faStream,
  faCommentDollar,
  faPrint,
  faTimes,
  faShoppingBasket,
  faMusic,
  faAt,
  faImages,
  faVideo,
  faLink,
  faTshirt,
  faTicketAlt,
  faCalendar,
  faAddressCard,
  faWineGlassAlt,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Corpo,
  Container,
  DashboardList,
  MsgItem,
  EvtItem,
  PrtItem,
  ToolsItem,
} from "./styles";
import Notificador, { notificar } from "../../components/Notificador";
import { Recado, RecadoAdmin } from "../../components/styled-components-global";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import Acessos from "./Acessos";
import Convites from "./Convites";
import Agenda from "./Agenda";

function Home(props) {
  let history = useHistory();
  const context = useContext(StateContext);
  const [bcast, setBcast] = useState({ todos: [], aluno: [] });
  const [fotos, setFotos] = useState({ todos: [], aluno: [] });
  const [pessoal, setPessoal] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [detalhesPedido, setDetalhesPedido] = useState([]);
  const [msgPedido, setMsgPedido] = useState(null);
  const [pedResumido, setPedResumido] = useState([]);
  const [comissao, setComissao] = useState([]);
  const [financeiro, setFinanceiro] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [viewRelPagamentos, setViewRelPagamentos] = useState(false);
  const [viewRelArquivos, setViewRelArquivos] = useState(false);
  const [viewUpsFormatura, setViewUpsFormatura] = useState(false);
  const [viewMedidasCamiseta, setViewMedidasCamiseta] = useState(false);
  const [viewResumo, setViewResumo] = useState(false);
  const [viewBanner, setViewBanner] = useState(false);
  const [minimoQuitado, setMinimoQuitado] = useState(false);
  const [percentualQuitado, setPercentualQuitado] = useState(0);
  const [convidado, setConvidado] = useState(false);

  const [viewUpComplementares, setUpComplementares] = useState(
    Boolean(EXIBE_UP_COMPLEMENTAR)
  );

  const [isLoading, setIsLoading] = useState(true);

  const descritivoAjuda = {
    mural: (
      <>
        <strong>Parabéns agora você é VIP!</strong>
        <p>
          Depois de concluir seu cadastro, o ideal e acessar seu portal uma vez
          por semana.
        </p>
        <p>
          Aqui é nosso meio de comunicação. Nele vamos postar dicas, tirar suas
          dúvidas, lhe manter informando sobre todos os passos que antecedem seu
          evento.
        </p>
      </>
    ),
    financeiro: (
      <>
        <p>
          Aqui você pode fazer seus pagamentos e acessar seu contrato clicando
          no olho ao lado de contratos.
        </p>
        <p>
          Para os pagamentos, clique em FAZER UM PAGAMENTO AGORA &gt; preencha
          os dados do seu endereço &gt; escolha o meio de pagamento (boleto,
          cartão de crédito ou PIX) &gt; aperte da tecla CONTRATO &gt; coloque o
          valor da parcela mensal.
        </p>
        <p>
          O comprovante do Pix se foi feito no nome do formando não precisa
          encaminhar para o financeiro.
        </p>
        <p>
          Você pode visualizar seus pagamentos direto na aba área financeira,
          caso haja alguma irregularidade pode nos chamar via Whatssapp 51
          99315-4438.
        </p>
      </>
    ),
    camiseta: (
      <>
        <p>
          O tamanho de sua camiseta deverá ser postado no seu portal no ato do
          cadastro. Caso já tenha fechado o prazo, e não seja possível favor
          enviar o tamanho juntamente com seu CPF via WhatsApp 51 9871-791473.
        </p>
        <p>
          Entrega da camiseta: na prova de togas ou no dia do ensaio de
          formatura, pois precisamos de um prazo de confecção de 30 dias após
          seu agendamento da prova de togas. Ou você pode combinar a retirada em
          nossa sede da produtora.
        </p>
      </>
    ),
    fotovideo: (
      <>
        <p>
          Tela inicial &gt; fotos e vídeos &gt; escolha a pasta (part 1/part 2/
          part 3) &gt; dentro da pasta tem as sub-pastas.
        </p>
        <p>
          Sempre olhar na vertical para ver na ordem das fotos. Basta acessar
          suas fotos, baixar e salvar elas em um local de sua confiança. O ideal
          é acessar através do computador para conseguir procurar suas fotos com
          calma e achar todas elas!
        </p>
        <p>
          O mesmo vale para seus vídeos. Você irá acessar na pasta que vai guiar
          para o link no drive, onde terá todos eles disponíveis. As fotos e os
          vídeos vão estar no portal até 6 meses após a data da cerimônia. Após
          este prazo você terá um custo de busca e armazenamento do material.
        </p>
      </>
    ),
    loja: (
      <>
        <p>
          Aqui você pode comprar seus acessos extras para formatura, voucher
          presente, quadros e outros. A compra é feita e paga direto pela nossa
          lojaestudio4.com.br. No caso de acessos extras para sua formatura,
          você irá receber os acessos no dia do ensaio da sua colação ou no dia
          do seu evento.
        </p>
      </>
    ),
    convitehomenagem: (
      <>
        <p>
          <strong>Música:</strong> Aqui nesta aba você irá colocar a música que
          vai tocar quando você for chamado, cortada da parte que quer que
          inicie.
        </p>
        <p>Não é preciso cortar o final da música!</p>
        <p>A música precisa ter no mínimo um minuto! </p>
        <p>
          Precisa estar em formato de MP3 e de preferência salva sem caracteres
          (exemplo: ´/ - ^ * ;)no nome.
        </p>
        <p>
          A música deverá estar postada no portal do formando com 60 dias de
          antecedência a data da formatura, e será conferida no dia do ensaio de
          formatura.
        </p>
        <p>
          O Formando que não postar neste prazo fica ciente que a produtora não
          terá tempo hábil para inclusão no projeto já executado para a
          formatura, e neste caso o formando(a) colará grau com uma música
          aleatória escolhida pela Produtora, nada tendo a reclamar. Obs: caso
          possua este item em seu pacote.
        </p>
        <p>
          <strong>Homenagem:</strong> O formando(a) deverá postar em seu portal
          03 fotos da sua família.
        </p>
        <p>
          A produtora vai criar um projeto de CLIP para transmissão aos que amam
          no dia da cerimônia de formaturas no momento das homenagens a quem
          ama.
        </p>
        <p>
          As fotos deverão ser postadas com prazo de 60 dias de antecedência a
          data da cerimônia de formatura.
        </p>

        <p>
          O Formando que não postar neste prazo fica ciente que a produtora não
          terá tempo hábil para inserir suas fotos no projeto já executado,
          ficando o mesmo(a) ciente que não terá suas fotos apresentadas no CLIP
          para homenagem a sua família, nada tendo a reclamar. Obs: caso possua
          este item em seu pacote.
        </p>
        <p>
          <strong>Convite:</strong> Aqui você irá postar a foto da prova de
          togas e ou BOOK. (quando contratado e realizado pela produtora), para
          a confecção do Convite Digital, a Produtora elabora uma arte para o
          convite, e disponibiliza o convite no portal do formando na aba
          convite digital, após quitação do pacote de formatura e por ordem de
          postagem. Nome no convite e o mesmo realizado em seu auto cadastro,
          caso queira esta alteração após o convite pronto taxa de alteração R$
          30,00. (Obs. caso tenha este item em seu pacote).
        </p>
        <p>
          <strong>Quadro:</strong> Por favor selecionar uma foto NA POSIÇÃO
          VERTICAL, da prova de togas ou do BOOK (caso o book tenha sido
          contratado).
        </p>
        <p>Após selecionar a foto é necessário postar na aba Foto do Quadro.</p>
        <p>
          Esta foto deverá ser postada em seu portal para a confecção do quadro
          com até 60 dias antecedência da data da cerimônia de formatura.
        </p>
        <p>
          O quadro só será confeccionado após a quitação do pacote de formatura
          com tempo hábil de confecção de máximo 45 dias de antecedência.
        </p>
        <p>
          Caso o formando perca o prazo de postagem no portal fica ciente que
          terá uma taxa de R$ 30,00 para o envio da foto direto no WhatsApp 51
          98179-1473,Ciente que esta taxa e somente o envio da foto, o
          recebimento não garante o prazo de entrega do quadro antes da
          formatura, caso o formando perca os prazos acima, o mesmo fica ciente
          que seu quadro deverá ser retirado na sede da produtora 30 dias após a
          data da realização da cerimônia de formatura mediante agendamento de
          retirada via WhatsApp 51-98179-1473. Obs: caso possua este item em seu
          pacote.
        </p>
        <p>
          <strong>Certificado:</strong> Para ilustrar mais sua solenidade, aqui
          você posta um PDF ou imagem do seu Certificado de Conclusão de Curso.
        </p>
      </>
    ),
  };

  const printThisRef = useRef();
  const handlePrintThis = useReactToPrint({
    content: () => printThisRef.current,
    documentTitle: "RelatórioE4",
  });

  const obterDados = async () => {
    const cacheLocal = localStorage.getItem("SPCart");
    notificar(
      ({ closeToast }) => (
        <span>
          <img style={{ width: 22, marginRight: "10px" }} alt="" src={help} />{" "}
          Caso precise de ajuda, clique em nossa assistente no topo dos módulos.
        </span>
      ),
      "amarelo",
      null,
      null,
      null,
      10000
    );
    if (cacheLocal && JSON.parse(cacheLocal).length) {
      context.actions.setCart(JSON.parse(cacheLocal));
    } else {
      const cacheCart = await handleGetCache("cart");
      if (cacheCart && cacheCart.content.length) {
        context.actions.setCart(cacheCart.content);
        notificar(
          ({ closeToast }) => (
            <p className="texto-toast">
              Parece que você deixou um pedido aberto em sua última visita.{" "}
              <br />
              <span className="bts-toast">
                <button
                  className="bt-toast"
                  onClick={() => history.push("/cart")}
                >
                  Ver
                </button>
                <button className="bt-toast" onClick={() => clearCart()}>
                  Descartar
                </button>
              </span>
            </p>
          ),
          /* `${prodCart.id} - ${prodCart.detalhes.nome} adicionado ao carrinho`, */
          null,
          "embaixo",
          null,
          null,
          25000
        );
      }
    }

    const bcs = await api.get(`/perfil/bcast`).then((dados) => {
      return dados.data;
    });

    const cad = await api.get(`/perfil/cadastro`).then((dados) => {
      return dados.data;
    });

    const fts = await api.get(`/perfil/fotos`).then((dados) => {
      return dados.data;
    });

    let fin = await api.get(`/perfil/financeiro`).then((dados) => {
      return dados.data;
    });

    const ped = await api.get(`/pedidos/consultar`).then((dados) => {
      return dados.data;
    });
    const com = await api.get(`/relatorios/comissao`).then((dados) => {
      return dados.data;
    });

    //controle de parcelas agrupadas

    fin[0].AlParcelas.filter((p) => p.parcagrupador === 1).map((it) => {
      it.parcvalor = fin[0].AlParcelas.filter(
        (p) => p.parcgrupo === it.parcid
      ).reduce((a, b) => a + b.parcvalor, it.parcvalor);
      it.parcacrescimo = fin[0].AlParcelas.filter(
        (p) => p.parcgrupo === it.parcid
      ).reduce((a, b) => a + b.parcacrescimo, it.parcacrescimo);
      it.parcdesconto = fin[0].AlParcelas.filter(
        (p) => p.parcgrupo === it.parcid
      ).reduce((a, b) => a + b.parcdesconto, it.parcdesconto);
      it.parcvalorpago = it.parcvalor;
    });
    fin[0].AlParcelas = fin[0].AlParcelas.filter((p) => p.parcgrupo === null);

    //consulta detalhes de um pedido para o modal
    const { retorno, tipo } = props.match.params;

    if (retorno && retorno.indexOf("-")) {
      //testa se endpoint tem padrão
      let ids = retorno.split("-");
      const parc = fin[0].AlParcelas.find((it) => it.parcid == ids[0]);
      let msg, cor;

      if (parc && parc.parcpagoem !== null) {
        msg =
          parc.parcvalor > 0
            ? `Seu pagamento de ${moeda(
                parc.parcvalor
              )} foi efetuado com sucesso. Obrigado.`
            : `Sua transação foi abonada pelos seus créditos conosco. Obrigado.`;
        cor = "verde";
      } else {
        msg = "Seu pagamento está sendo processado pela operadora de cartões.";
        cor = null;
      }

      switch (tipo) {
        case "E-commerce":
          handlePedidoDetails(ids[1], ped);
          setMsgPedido({
            msg: `${msg} Qualquer alteração de estado em seu PEDIDO será notificada por email.`,
            cor: cor,
          });
          break;
        case "Contrato":
        case "Extras":
          notificar(msg, cor, null, () => history.push("/"), null);
          break;
        default:
      }
    }

    //controle de mínimo quitado
    const tPagas = fin[0].AlParcelas.filter(
      (p) =>
        p.parcpagoem !== null &&
        (p.parctipo === "Contrato" || p.parctipo === "Desconto")
    );
    const vPago = tPagas.reduce((ant, cur) => ant + cur.parcvalorpago, 0);

    //setagem de conteúdos para o portal
    setIsLoading(false);
    setBcast(bcs);
    setFotos(fts);
    setFinanceiro(fin);
    setPessoal(cad);
    setPedidos(ped);
    setComissao(com);

    context.actions.setPerfil({ financeiro: fin, cadastro: cad, pedidos: ped });
    setPercentualQuitado(cad.AlFormDados.FormMinimoPago);
    setConvidado(Boolean(cad.AlCurso == FLAG_CONVIDADO_ID));
    setMinimoQuitado(
      Boolean(
        vPago >= (fin[0].cttotalprazo / 100) * cad.AlFormDados.FormMinimoPago
        //context.state.cadastro.AlFormDados.FormMinimoPago
      )
    );

    setViewModal(viewBanner);
  };
  useEffect(() => {
    obterDados();
  }, []);

  function handleCloseModal() {
    setViewModal(false);
    setViewBanner(false);
    setViewRelPagamentos(false);
    setViewRelArquivos(false);
    setViewUpsFormatura(false);
    setViewMedidasCamiseta(false);
    setViewResumo(false);
    setDetalhesPedido([]);
    setMsgPedido(null);
    setPedResumido([]);
    history.push("/");
  }

  function handleClickAlbum(ev, tp, lnk) {
    switch (tp) {
      case 0:
        return history.push(`/album/${ev}/${ALBUM_LIMIT}/1`);
      case 1:
      case 2:
        window.open(lnk, "_blank");
    }
  }

  function handleRelatorioPagamento() {
    setViewModal(true);
    setViewRelPagamentos(true);
  }

  function handleRelatorioArquivos() {
    setViewModal(true);
    setViewRelArquivos(true);
  }
  function handleUpsFormatura() {
    setViewModal(true);
    setViewUpsFormatura(true);
  }

  function handleMedidasCamiseta() {
    setViewModal(true);
    setViewMedidasCamiseta(true);
  }

  function handleConvitesBaixar() {
    api
      .post(
        "/downConvite",
        { caminho: pessoal.AlConvite },
        { responseType: "arraybuffer" }
      )
      .then((response) => new Blob([response.data]))
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "convite.jpg");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        notificar("Seu convite está sendo baixado.", "verde", "embaixo");
      })
      .catch((err) => {
        notificar(
          "Não foi possível baixar este convite.",
          "vermelho",
          "embaixo"
        );
      });
  }

  async function handlePedidoDetails(pedDetalhes, ped, e = null) {
    if (e) e.stopPropagation();
    const pedRes = ped.filter((it) => it.pedId == pedDetalhes)[0];
    const dadosPedido = await api.get(`/pedidos/detalhar/${pedDetalhes}`);
    if (dadosPedido.data) {
      setPedResumido(pedRes);
      setDetalhesPedido(dadosPedido.data);
      setViewResumo(true);
      setViewModal(true);
    }
  }

  const modalStyle = {
    content: {
      maxWidth: "90vw",
      minWidth: "300px",
      margin: "auto",
      fontSize: "14px !important",
    },
  };

  let botoesModalStyle = {
    padding: "15px",
  };

  const containerBotoesModalStyle = {
    display: "flex",
    justifyContent: "flex-end",
  };

  return (
    <Corpo>
      {convidado && (
        <RecadoAdmin convidado>
          <p title="Seja bem-vindo Ao Estúdio 4 Produções.">
            Você é um convidado.
          </p>
        </RecadoAdmin>
      )}
      <Modal
        isOpen={viewModal}
        onRequestClose={() => handleCloseModal()}
        style={modalStyle}
        ref={printThisRef}
      >
        <p style={containerBotoesModalStyle}>
          {!viewUpsFormatura && (
            <>
              <button
                style={botoesModalStyle}
                onClick={handlePrintThis}
                title="Imprimir este relatório"
                className="icone"
              >
                <FontAwesomeIcon style={{ fontSize: 15 }} icon={faPrint} />
              </button>
              {/* <button
                style={botoesModalStyle}
                onClick={handleEmailThis}
                title="Mandar por email"
                className="icone"
              >
                <FontAwesomeIcon style={{ fontSize: 15 }} icon={faAt} />
              </button> */}
            </>
          )}
          <button
            style={botoesModalStyle}
            onClick={() => handleCloseModal()}
            className="icone"
            title="Cancelar e fechar"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </p>
        {viewRelPagamentos && (
          <RelatorioPagamentos
            dados={comissao}
            formatura={pessoal.AlFormDados}
          />
        )}
        {viewMedidasCamiseta && (
          <div>
            <h2>Escolha a medida da sua camiseta</h2>
            <hr />
            <img style={{ maxWidth: "95%" }} src={med_camisetas_g} />
          </div>
        )}
        {viewRelArquivos && (
          <RelatorioArquivos dados={comissao} formatura={pessoal.AlFormDados} />
        )}
        {viewUpsFormatura && <Uploads tipo="formatura" />}
        {viewResumo && (
          <OrderDetails
            pedido={pedResumido}
            detalhes={detalhesPedido}
            mensagem={msgPedido}
          />
        )}
        {viewBanner && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={bannerBlk}
              style={{ width: "100%", maxWidth: "450px", margin: "0 auto" }}
              alt="Aproveite!"
            />
          </div>
        )}
      </Modal>
      {/* <p style={{fontSize: "0.6em"}}>{`Sua sessão expira em ${(expire /(60000)).toFixed(0)} minutos.` }</p> */}
      <Container>
        {/* RECADO DE TOPO */}
        {/* <Recado style={{ width: '100%' }}>
          <p>Estaremos em <strong>recesso de final de ano</strong> entre 23/12/2022 e 05/01/2023. Atendimento normal a partir de 06/01/2023. <strong>Boas Festas!</strong></p>
          <FontAwesomeIcon
            style={{ margin: "10px", fontSize: "1.4rem" }}
            icon={faWineGlassAlt}
          />
        </Recado> */}
        {/* DADOS DA FORMATURA */}
        <DashboardList>
          {/* MURAL */}
          <DashboardItem
            icon={faComments}
            caption="Mural do Estúdio 4 Produções"
            description="Comunicados para a turma e recados para você."
            loading={isLoading}
            ajuda={descritivoAjuda.mural}
          >
            {bcast.todos.map((msg) => (
              <MsgItem key={msg.crmid}>
                <p dangerouslySetInnerHTML={{ __html: nl2br(msg.crmdesc) }}></p>
                <p className="msg-rodape">
                  em{" "}
                  {Intl.DateTimeFormat("pt-BR").format(
                    new Date(msg.crmtimestamp)
                  )}
                </p>
              </MsgItem>
            )) || "Sem mensagens para sua turma."}
            {bcast.aluno.map((msg) => (
              <MsgItem key={msg.crmid}>
                <p dangerouslySetInnerHTML={{ __html: nl2br(msg.crmdesc) }}></p>
                <p className="msg-rodape">
                  em{" "}
                  {Intl.DateTimeFormat("pt-BR").format(
                    new Date(msg.crmtimestamp)
                  )}
                </p>
              </MsgItem>
            )) || <p>"Sem mensagens para você."</p>}
          </DashboardItem>

          {/* DATA E LOCAL */}
          {(pessoal?.AlFormDados?.FormVerDados && (
            <DashboardItem
              icon={faCalendar}
              caption="Data e local da sua formatura"
              description="Informações sobre localizaçao do seu evento"
              loading={isLoading}
            >
              <p
                style={{
                  fontSize: "1.2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <span >{databoa(pessoal.AlFormDados.FormData)} no {pessoal.AlFormDados.FormLocal}</span> */}
                <span>
                  {databoa(pessoal.AlFormDados.FormData)} às{" "}
                  {pessoal.AlFormDados.FormHora} no{" "}
                  {pessoal.AlFormDados.FormLocal}
                </span>
              </p>
            </DashboardItem>
          )) ||
            null}

          {/* CONTRATOS */}
          {!convidado && pessoal?.AlFormDados ? (
            <DashboardItem
              icon={faFileContract}
              caption="Área Financeira"
              description="Todas as informações sobre os seus produtos contratados e formas
            de pagamento."
              loading={isLoading}
              ajuda={descritivoAjuda.financeiro}
            >
              {financeiro.map((contrato) => (
                <ContractItem
                  key={contrato.ctid}
                  contrato={contrato}
                  formatura={pessoal.AlFormDados}
                  parcelas={financeiro[0].AlParcelas}
                  extras={pessoal.AlExtras}
                  pacote={pessoal.ItensPacotes}
                />
              ))}
            </DashboardItem>
          ) : (
            null || null
          )}

          {/* CAMISETAS */}
          {(!convidado && (
            <DashboardItem
              icon={faTshirt}
              caption="Medida da sua camiseta"
              description="Escolha aqui a medida da sua camiseta."
              loading={isLoading}
              ajuda={descritivoAjuda.camiseta}
            >
              {pessoal.AlFormDados ? (
                <Camisetas
                  formatura={pessoal.AlFormDados}
                  medida={pessoal.AlMedida}
                  ampliar={() => handleMedidasCamiseta()}
                />
              ) : null}
            </DashboardItem>
          )) ||
            null}

          {/* CONVITES */}
          {(!convidado && pessoal?.AlConvite && (
            <DashboardItem
              icon={faAddressCard}
              caption="Seu Convite Digital"
              description="Baixe seu convite digital."
              loading={isLoading}
              ajuda={descritivoAjuda.convite}
            >
              <Convites
                baixar={() => handleConvitesBaixar()}
                thumb={pessoal.AlConvite}
              />
            </DashboardItem>
          )) ||
            null}

          {/* ACESSOS */}
          {/* {!convidado && (pessoal.AlFormDados?.FormTotalAcessos > 0) ? (
            <DashboardItem
              icon={faTicketAlt}
              caption="Acessos extras para convidados"
              description="Adquira lugares extras na platéia para seus convidados."
              loading={isLoading}
            >
              <Acessos cadastro={pessoal} contrato={financeiro[0].ctid} />
            </DashboardItem>
          ) : null || null} */}

          {/* CONTEÚDOS */}
          {(fotos.aluno.length || fotos.todos.length) && !pessoal.AlRestrito ? (
            <DashboardItem
              icon={faPhotoVideo}
              caption="Fotos e Vídeos"
              description="Fotos, vídeos e links de seus eventos realizados por nós."
              loading={!(fotos.aluno.length || fotos.todos.length)}
              ajuda={descritivoAjuda.fotovideo}
            >
              {minimoQuitado || convidado ? (
                <>
                  {fotos.aluno.filter((it) => it.evtpmidia === 0).length ||
                  fotos.todos.filter((it) => it.evtpmidia === 0).length ? (
                    <>
                      <p
                        className="card-title"
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          color: "#999",
                        }}
                      >
                        <FontAwesomeIcon
                          className="icon-card-title"
                          icon={faImages}
                        />{" "}
                        Fotos dos Eventos
                      </p>
                      <div className="evt-content">
                        {fotos.aluno
                          .filter((it) => it.evtpmidia === 0)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                        {fotos.todos
                          .filter((it) => it.evtpmidia === 0)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                      </div>
                    </>
                  ) : null}
                  {fotos.aluno.filter((it) => it.evtpmidia === 1).length ||
                  fotos.todos.filter((it) => it.evtpmidia === 1).length ? (
                    <>
                      <p
                        className="card-title"
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          color: "#999",
                        }}
                      >
                        <FontAwesomeIcon
                          className="icon-card-title"
                          icon={faVideo}
                        />{" "}
                        Videos dos Eventos
                      </p>
                      <div className="evt-content">
                        {fotos.aluno
                          .filter((it) => it.evtpmidia === 1)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                        {fotos.todos
                          .filter((it) => it.evtpmidia === 1)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                      </div>
                    </>
                  ) : null}
                  {fotos.aluno.filter((it) => it.evtpmidia === 2).length ||
                  fotos.todos.filter((it) => it.evtpmidia === 2).length ? (
                    <>
                      <p
                        className="card-title"
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          color: "#999",
                        }}
                      >
                        <FontAwesomeIcon
                          className="icon-card-title"
                          icon={faLink}
                        />{" "}
                        Links para Conteúdo dos Eventos
                      </p>
                      <div className="evt-content">
                        {fotos.aluno
                          .filter((it) => it.evtpmidia === 2)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                        {fotos.todos
                          .filter((it) => it.evtpmidia === 2)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <Recado>
                  Para ter acesso aos eventos que realizamos você precisa ter
                  quitado ao menos {percentualQuitado}% do valor de seu
                  contrato.
                </Recado>
              )}
            </DashboardItem>
          ) : null}

          {/* LOJA */}

          <DashboardItem
            icon={faShoppingBasket}
            caption="Lojinha do Formando"
            description="Adquira produtos especialmente preparados para você."
            loading={!(fotos.aluno.length || fotos.todos.length)}
            ajuda={descritivoAjuda.loja}
          >
            <div className="loja-content">
              <PrtItem href="https://lojaestudio4.com.br/" target="_blank">
                <img
                  src={banner}
                  style={{ width: "100%" }}
                  alt="Conheça nossos produtos"
                />
              </PrtItem>
            </div>
          </DashboardItem>

          {/* PEDIDOS */}
          {pedidos.length && !pessoal.AlRestrito ? (
            <DashboardItem
              icon={faShoppingBag}
              caption="Pedidos de Fotos"
              description="Estado de cada pedido seu feito em nosso sistema."
              loading={isLoading}
            >
              {pedidos.map((ped) => (
                <OrderItem
                  pedido={ped}
                  key={ped.pedId}
                  onClick={(e) => handlePedidoDetails(ped.pedId, pedidos, e)}
                />
              ))}
            </DashboardItem>
          ) : null}

          {/* ARQUIVOS */}
          {!convidado && !pessoal.AlRestrito ? (
            <DashboardItem
              icon={faCloudUploadAlt}
              caption="Convites e Homenagens"
              description="Uploads de material para ilustrar a sua colação e seus produtos do pacote."
              loading={isLoading}
              ajuda={descritivoAjuda.convitehomenagem}
            >
              <p
                className="card-title"
                style={{
                  textAlign: "center",
                  marginTop: "15px",
                  color: "#999",
                }}
              >
                <FontAwesomeIcon
                  className="icon-card-title"
                  icon={faPhotoVideo}
                />{" "}
                FOTOS e MÚSICA para a colação
              </p>
              <Uploads tipo="individual" />
              {(viewUpComplementares && (
                <>
                  <p
                    className="card-title"
                    style={{
                      textAlign: "center",
                      marginTop: "15px",
                      color: "#999",
                    }}
                  >
                    <FontAwesomeIcon
                      className="icon-card-title"
                      icon={faImages}
                    />{" "}
                    Fotos para QUADRO e CONVITE
                  </p>
                  <Uploads tipo="complementar" />
                </>
              )) ||
                null}
              <p
                className="card-title"
                style={{
                  textAlign: "center",
                  marginTop: "15px",
                  color: "#999",
                }}
              >
                <FontAwesomeIcon
                  className="icon-card-title"
                  icon={faCertificate}
                />{" "}
                Certificado de Formatura
              </p>
              <Uploads tipo="certificado" />
            </DashboardItem>
          ) : (
            null || null
          )}

          {/* FERRAMENTAS DA COMISSÃO */}
          {comissao && !pessoal.AlRestrito ? (
            <DashboardItem
              icon={faTools}
              caption="Ferramentas da Comissão"
              description="Ferramentas para ajudar você a gerenciar sua turma."
              loading={isLoading}
            >
              {/* <ToolsItem>
              <FontAwesomeIcon className="icon-tools" icon={faCalendarDay} />{" "}
              Protocolo de solicitação de eventos
            </ToolsItem>
            <ToolsItem>
              <FontAwesomeIcon className="icon-tools" icon={faTshirt} />{" "}
              Protocolo de solicitação de camisetas
            </ToolsItem> */}
              <ToolsItem onClick={() => handleRelatorioPagamento()}>
                <FontAwesomeIcon
                  className="icon-tools"
                  icon={faCommentDollar}
                />{" "}
                Relatório de pagamentos da turma
              </ToolsItem>
              <ToolsItem onClick={() => handleRelatorioArquivos()}>
                <FontAwesomeIcon className="icon-tools" icon={faStream} />{" "}
                Relatório de arquivos enviados pela turma
              </ToolsItem>

              <ToolsItem onClick={() => handleUpsFormatura()}>
                <FontAwesomeIcon className="icon-tools" icon={faMusic} />{" "}
                Carregar músicas para a formatura
              </ToolsItem>
            </DashboardItem>
          ) : null}
        </DashboardList>
      </Container>
    </Corpo>
  );
}

export default withRouter(Home);
