import React, { useState } from "react";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DashItem, DashboardItemBody } from "./styles";
import {
  faWindowMaximize,
  faWindowMinimize,
} from "@fortawesome/free-solid-svg-icons";
import help from "../../../assets/icons/svg/flat/046-customer-service.svg";

const descritivos = {};

export default function DashboardItem(props) {
  const {
    icon,
    caption,
    description,
    loading,
    children,
    ajuda = "",
    estado = false,
    destaque = false,
  } = props;
  const [minMax, setMinMax] = useState(estado);
  const [ajudar, setAjudar] = useState(false);

  const handleAjudar = () => {
    setMinMax(true);
    setAjudar(!ajudar);
  };
  return (
    <DashItem aberto={minMax}>
      <p
        className="card-title"
        /* onClick={(e) => {
          e.stopPropagation();
          if (minMax === false) setMinMax(true);
        }} */
        title={minMax === false ? "Clique para expandir este módulo." : ""}
        style={minMax === false ? { cursor: "pointer" } : {}}
      >
        <span>
          <FontAwesomeIcon className="icon-card-title" icon={icon} /> {caption}{" "}
        </span>

        <span>
          {(ajuda !== "" && (
            <img
              title="Precisa de ajuda?"
              onClick={() => handleAjudar()}
              style={{ width: 22, marginRight: "10px" }}
              alt=""
              src={help}
            />
          )) ||
            null}
          <FontAwesomeIcon
            title="Contrair/Expandir este módulo."
            className="icone minmax"
            onClick={() => setMinMax(!minMax)}
            icon={minMax ? faWindowMinimize : faWindowMaximize}
          />
        </span>
      </p>
      <p className="card-description"> {description}</p>
      {(ajudar && <div className="card-ajuda">{ajuda}</div>) || null}
      <Loader id="load_list" visible={loading} type="ThreeDots" color="#666" />
      <DashboardItemBody>{!loading && children}</DashboardItemBody>
    </DashItem>
  );
}
